// This style is for task-board in time and action plan
.task_card_wrapper {
  border: 1px solid #ced4da;
  background: white;
  border-radius: 15px;
  // height: 78vh;
  .close_modal {
    position: absolute;
    cursor: pointer;
    top: 2px;
    right: 2px;
    width: 20px;
    height: 20px;
    color: red;
  }
  .taskCommentCard {
    .cardHeader {
      h4 {
        white-space: nowrap;
        width: 120;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .cardBody {
      .userImage {
        height: 45px;
        width: 45px;
        object-fit: cover;
        border: 2px solid #d1edf9;
        flex-shrink: 0;
        overflow: hidden;
      }
    }
    .bg_blue {
      background-color: #007bff !important;
      border-radius: 15px 15px 0px 0px;
      margin: 0px;
    }

    .single_chat {
      height: 49vh;
      overflow: auto;
    }
    .card_footer {
      // box-shadow: 0 -0.5rem 1rem rgba(149 146 146 / 15%);
      box-shadow: 0 -0.5rem 1rem rgba(146, 146, 146, 0.15) !important;
    }
  }
}

/* *********************************** */
.timeAndActionMoalOuter {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;

  p {
    margin-bottom: 5px;
  }
  .btn_read_more {
    margin-top: 15px;
  }
  .timeAndActionMoalInfoBox {
    width: 32%;
    margin-bottom: 25px;

    .itemCardLayout_itemCardLayout__2vlES {
      height: 100%;
    }
  }
}
.comment_card_wrapper {
  color: #484848;
  text-align: left;
  padding: 20px;
  p {
    margin-bottom: 10px;
  }
  h5 {
    font-size: 18px;
    line-height: normal;
    margin-bottom: 5px;
  }
  h6 {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 5px;
  }
  .timeAndActionModal {
    position: relative;
    height: 100%;
  }

  .commentForm {
    max-height: 550px;
    overflow: auto;
    padding-bottom: 10px;
    text-align: left;

    .commentInfoBoxInner {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding-bottom: 20px;
    }
    .commentInfoBox {
      .proImg {
        height: 70px;
        border-radius: 50%;
        width: 70px;
      }
      .proDtails {
        padding-left: 20px;
      }
    }
    .commentFullInfo {
      padding-bottom: 20px;
      h6 {
        span {
          font-weight: 400;
        }
      }
    }

    .commentReplayBox {
      h4 {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
  }
  .noteCommentInput {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;

    textarea {
      height: 40px;
      border: 1px solid #bebebe !important;
      border-radius: 5px;
      outline: none;
      padding: 8px 50px 8px 10px;
    }
    textarea:focus {
      outline: none;
      border: none;
    }
    .comment_submit {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

// This style is for OrderTable
table.formTable tbody tr td .link_space {
  margin-left: 50px;
}
