.rfq_innrer_certificate_slider {
  .slick-track {
    width: 560px;
    opacity: 1;
    transform: translate3d(-140px, 0px, 0px);
    display: flex;
    align-items: center;
  }
  .slick-slide {
    .certificate_image_warp {
      text-align: center;
      padding: 0 20px;
      img {
        // width: 100%;
        height: auto;
      }
    }
  }
}
