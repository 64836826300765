.omd-header {
  height: initial !important;
  padding-right: 40px;
  padding-left: 20px;
  background-color: #fff;
  height: 80px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.24);
  position: relative;

  .image_size {
    width: 82px;
  }

  .left-side {
    h2 {
      margin-bottom: 0;
      font-weight: 700;
    }
    span {
      font-size: 20px;
      color: #00000070;
    }
  }
  .right-side {
    .nav-menus {
      padding-top: 24px !important;
      padding-bottom: 24px !important;
    }
  }

  //   .nav-right {
  //     & > ul {
  //       & > li {
  //         &:first-child {
  //           width: initial;
  //         }
  //       }
  //     }
  //   }
}
