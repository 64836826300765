// .react-kanban-column {
//   width: 40%;
// }
.kanbanTaskCard {
  width: 250px;
  &:focus {
    background-color: #00000020 !important;
  }
  p {
    margin-bottom: 0;
  }
  .description {
    overflow-wrap: break-word;
  }
  .date {
    font-size: 12px;
  }
  .user {
    height: 25px;
    width: 25px;
    object-fit: cover;
  }
}
