input,
select {
  &.is-error {
    border-color: $theme-red !important;
  }
}

.textInput {
  background: #fff;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 300;
  border: none;
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
  &.size-sm {
    height: 38px;
  }
  &.size-md {
    height: 55px;
    font-size: 20px;
  }
  &.shadow-md {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
    &.is-invalid {
      box-shadow: 0px 3px 10px rgba(245, 0, 0, 0.3);
    }
  }
  &.variant-outlined {
    box-shadow: none;
    border: 1px solid $gray;
  }

  &.is-error {
    border-color: $theme-red !important;
  }
}

.selectInput {
  &.shadow-md {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  }
  &.size-md {
    height: 55px;
    font-size: 20px;
  }
  &.size-sm {
    height: 38px;
    font-size: 12px;
  }
  &.variant-outlined {
    box-shadow: none;
    // border: 1px solid $gray !important;
  }
  &.is-error {
    border-color: $theme-red !important;
  }
}
