.omdButton {
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.55);
  cursor: pointer;
  text-decoration: none;
  font-weight: 300;
  position: relative;

  &.size-xs {
    font-size: 9px;
    min-width: 50px;
    padding: 7px 12px;
  }
  &.size-sm {
    font-size: 12px;
    min-width: 90px;
    padding: 7px 15px;
  }
  &.size-rag {
    font-size: 20px;
    min-width: 240px;
    padding-top: 8px;
    padding-bottom: 8px;
    .spinner-border {
      height: 20px;
      width: 20px;
      font-size: 13px;
    }
  }

  &.variant-solid {
    &.color-light {
      background-color: $white;
      color: $black;
    }
    &.color-theme-primary {
      background-color: $theme-green;
      color: $white;
    }
    &.color-danger {
      background-color: $theme-red;
      color: $white;
    }
    &.color-primary {
      background-color: $primary-color;
      color: $white;
    }
  }

  &.variant-outlined {
    border: 1px solid;
    background-color: transparent;
    &.color-light {
      border: none;
      color: $white;
      text-align: left;
      margin-left: 60px;
    }
    &.color-theme-primary {
      border-color: $theme-green;
      color: $theme-green;
    }
    &.color-danger {
      border-color: $theme-red;
      color: $theme-red;
    }
  }

  &.noShadow {
    box-shadow: none;
  }

  &.icon {
    min-width: unset;
  }

  &.disabled {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  .countBadge {
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);

    &.color-danger {
      background-color: $theme-red;
      color: $white;
    }
    &.color-primary {
      background-color: $primary-color;
      color: $white;
    }
    &.color-success {
      background-color: $theme-green;
      color: $white;
    }
  }
}
