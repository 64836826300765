.page-body {
  padding: 20px;
}

.commercialStoreInfo {
  max-width: 1010px;
  width: 100%;
  margin: 0 auto;

  .storeValueInfoLeft {
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 25px;
    margin-bottom: 25px;
    border-radius: 10px;
    cursor: pointer;

    .totalTitle {
      font-size: 17px;
    }
    .totalValue {
      font-weight: 500;
      font-size: 45px;
      span {
        font-size: 17px;
        font-weight: 400;
      }
    }
  }
  .storeValueInfoLeft:hover,
  .storeValueInfoLeft.active {
    background: #f1f1f1;
  }

  .storeValueInfoLeft:last-child {
    margin-bottom: 0;
  }

  .storeChartValueRight {
    background: #f1f1f1;
    height: 100%;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

    .storeGraphTop {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .totalTitle {
        font-size: 19px;
        font-weight: 500;
      }
      .totalValue {
        font-size: 19px;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        border: 1px solid #000;
      }
    }
    .pieChartWrap {
      padding-top: 40px;
    }
  }
}

.timeAndActionMoalWrap {
  padding-top: 20px;
  table {
    tr {
      td {
        padding-right: 10px;
      }
    }
  }
  select,
  input {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    margin-bottom: 10px;
  }
  .inputboxwrap {
    margin-bottom: 10px;
    textarea {
      width: 100%;
      height: 120px;
      padding: 5px;
    }
    select,
    input {
      width: 100%;
      height: 40px;
      padding: 0 10px;
    }
    label {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .mx-auto {
    margin: inherit !important;
  }
}

.costManagementWrapper {
  Form {
    width: 100%;
    .textInput {
      margin-bottom: 20px;
      box-shadow: none;
      border: 1px solid #6e6e6e;
      border-radius: 10px;
      height: 45px;
    }
    .addBtn {
      margin-top: 20px;
    }
  }
  .title {
    padding: 10px 0;
  }
  .costManagementBox {
    position: relative;
    .costButton {
      position: absolute;
      right: 25px;
      top: 22px;
      min-width: 65px;
    }
  }
  .sendrfq {
    border: 1px solid #54a958;
    color: #000 !important;
    margin-right: 10px;
    background: none !important;
  }
}
.rodal {
  .textInput.size-md,
  .selectInput.size-md {
    height: 45px;
    font-size: 16px;
  }
  label {
    margin-bottom: 10px !important;
    font-size: 16px;
  }
}

.requestForQuotationForm {
  .profileImageUploadBox {
    margin: 5px;
  }
}

.costsheetRfqIcon {
  padding: 10px 0;
  a {
    display: inline-block;
    margin-left: 10px;
    text-align: center;
    i {
      color: #54a958;
      font-size: 22px;
    }
    span {
      display: block;
      font-size: 10px;
      color: #000;
    }
  }
}
.costSheetRfqTop {
  background: #54a958;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  h3 {
    font-size: 18px;
    color: #fff;
    margin: 8px 0 0;
  }
  .omdButton {
    background: #fff !important;
    color: #54a958 !important;
    border: 1px solid #bebebe;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 15px;
  }
}

.rfqDataPage {
  .itemCardLayout_itemCardLayout__1n65S {
    padding: 0;
    // border-radius: 0 0 10px 10px;
  }
  table {
    thead {
      border-bottom: 1px solid #ccc;
      th {
        border-left: 1px solid #ccc;
        padding: 10px;
      }
    }
    tr {
      border-top: 1px solid #ccc;
      td {
        border-left: 1px solid #ccc;
      }
      &:first-child {
        border-top: none;
      }
    }

    .rfqInnerTable {
      tr {
        td {
          padding: 10px 0;
          border-left: none;
        }
      }
    }
    .qtyTable {
      tr {
        td {
          border-left: none;
          border-bottom: none;
        }
      }
    }
  }
}

.count_and_arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: black;
    font-size: 16px;
  }
}

.task_arrow_icon {
  color: black;
  margin-left: 20px;
}

.update_arrow_icon {
  color: black;
  margin-right: 20px;
}

.btbGeneratePage .itemCardLayout_itemCardLayout__Fxdkv {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 15px;
}

/* Steps css Start  */
.buyer_oms_seteps_wrap ul.form-stepper .form-stepper-circle {
  position: relative;
}
.buyer_oms_seteps_wrap .form-stepper .label {
  font-size: 12px;
  margin-top: 0.5rem;
  color: #d2d2d2;
  font-weight: 600;
  max-width: 108px;
  line-height: 18px;
}
.buyer_oms_seteps_wrap ul.form-stepper .form-stepper-circle span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  color: #d2d2d2;
  border: 5px solid #fff;
  width: 18px;
  height: 18px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 0;
  display: inline-block;
}
.buyer_oms_seteps_wrap .form-stepper-horizontal {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
}
.buyer_oms_seteps_wrap .form-stepper-horizontal li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  list-style: none;
  position: relative;
}
.buyer_oms_seteps_wrap ul.form-stepper li .step .form-stepper-circle {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 0;
  line-height: normal;
  text-align: center;
  background: #d2d2d2;
  border-radius: 50%;
}
.buyer_oms_seteps_wrap .form-stepper-horizontal li:not(:last-child):after {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 4px;
  content: '';
  top: 14px;
  min-width: 30px;
}
.buyer_oms_seteps_wrap .form-stepper-horizontal li:after {
  background-color: #dee2e6;
}
.buyer_oms_seteps_wrap .form-stepper-horizontal li.form-stepper-completed:after {
  background-color: #54a958;
}
.buyer_oms_seteps_wrap .form-stepper .form-stepper-completed .form-stepper-circle {
  background-color: #54a958 !important;
  color: #fff;
  width: 34px !important;
  height: 34px !important;
  top: -5px;
}
.buyer_oms_seteps_wrap .form-stepper .form-stepper-completed .form-stepper-circle span {
  background-color: #54a958 !important;
  border: 5px solid #fff;
  width: 22px;
  height: 22px;
}
.buyer_oms_seteps_wrap .form-stepper .form-stepper-completed .label {
  color: #54a958 !important;
  font-weight: 600;
}
.buyer_oms_seteps_wrap .form-stepper-horizontal li.form-stepper-rejected:after {
  background-color: #54a958;
}
.buyer_oms_seteps_wrap .form-stepper .form-stepper-rejected .form-stepper-circle {
  background-color: #fa3939 !important;
  color: #fff;
}
.buyer_oms_seteps_wrap .form-stepper .form-stepper-rejected .form-stepper-circle span {
  background-color: #fa3939 !important;
  border: 5px solid #fff;
}
.buyer_oms_seteps_wrap .form-stepper .form-stepper-rejected .label {
  color: #fa3939 !important;
}
/* Steps css End  */
