//Badge
.mainBadge {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.55);
  display: inline-block;
  border-radius: 5px;

  &.size-reg {
    font-size: 15px;
    padding-left: 20px;
    padding-right: 20px;
  }
  &.size-lg {
    font-size: 18px;
    padding: 4px 20px;
    line-height: 27px;
  }

  &.variant-solid {
    &.color-light {
      background-color: $white;
      color: $black;
    }
    &.color-info {
      background-color: $primary-color;
      color: $white;
    }
    &.color-success {
      background-color: $theme-green;
      color: $white;
    }
    &.color-danger {
      background-color: $theme-red;
      color: $white;
    }
  }
}

//Icon Badge
.iconBadge {
  position: relative;
  margin-right: 9px;
  margin-top: 9px;
  .count {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: $theme-red;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    right: 0;
    top: 0;
    transform: translate(40%, -40%);
    color: $white;
  }
}
