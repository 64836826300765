.notesSuppliersWrap {
  padding: 10px;
  .btn_goBack {
    background: none;
    border: none;
    font-size: 18px;
    color: #575757;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 20px;

    .svg-inline--fa {
      margin-right: 15px;
      font-size: 22px;
      margin-top: 2px;
      float: left;
    }
  }

  .notesSuppliersListBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #ededed;
    padding: 10px 30px;
    margin-bottom: 20px;
    border-radius: 3px;
    background: #fff;
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    opacity: 0.7;
    &:hover {
      opacity: 1;
      border: 2px solid #759098;
      -webkit-box-shadow: 0px 0px 12px 0px rgba(143, 166, 172, 1);
      -moz-box-shadow: 0px 0px 12px 0px rgba(143, 166, 172, 1);
      box-shadow: 0px 0px 12px 0px rgba(143, 166, 172, 1);
      cursor: pointer;
    }
  }
  .supplierProfile {
    display: flex;
    align-items: center;
  }
  .suppName {
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 0 20px;
    max-width: 200px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .suppAddress {
    font-style: italic;
    margin-left: 30px;
    font-weight: 300;
    max-width: 200px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .suppDetails {
    max-width: 500px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .suppDate {
    font-weight: 300;
  }
  .suppIconBox {
    .svg-inline--fa {
      font-size: 22px;
      font-weight: 300;
      margin-left: 30px;
      color: #486e78;
    }
  }

  .notesSuppliersTitleBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }

  .notesSuppliersDetailsBox {
    background: #fff;
    padding: 20px;
    .topTile {
      padding: 20px 30px;
      border-bottom: 1px solid #bdbcbc;
      font-size: 20px;
      color: #656464;
      span {
        color: #a7a6a6;
        padding-right: 20px;
      }
    }
  }
  .notesDetailsCard {
    background: #ffffff;
    border: 2px solid #285460;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(40, 84, 96, 1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(40, 84, 96, 1);
    box-shadow: 0px 0px 5px 0px rgba(40, 84, 96, 1);
    border-radius: 5px;
    .noteContectBat {
      padding: 30px;
      font-size: 14px;
      line-height: normal;
      font-weight: 400;
    }

    .attachtedFileBox {
      display: inline-block;
      width: 100%;
      .fileImg {
        float: left;
        width: 150px;
        height: 150px;
        margin: 0px 20px 10px 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border: 2px solid #dddcdc;
        }
      }
    }
    .attachementBox {
      padding: 20px 0 0;
      h4 {
        font-size: 18px;
        color: #000000;
        margin-bottom: 20px;
      }
    }
  }
  .repliesBox {
    padding: 20px 0 0;
    .notesSuppliersTitleBar {
      margin-bottom: 20px;
    }
    .noteContectBat {
      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid #dddcdc;
    }
    h6 {
      font-size: 18px;
      border-bottom: 1px solid #eee;
      padding: 20px 0;
      margin: 0 0 30px;
    }
    .svg-inline--fa {
      font-size: 22px;
      margin-right: 10px;
    }
  }
  .replies_button {
    .btnReply {
      background: none;
      border: 1px solid #54a958;
      color: #54a958;
      padding: 0px 20px;
      border-radius: 5px;
      margin-right: 10px;
      font-size: 16px;
      font-weight: 600;
      height: 45px;
      line-height: 30px;

      .svg-inline--fa {
        font-size: 18px;
        float: left;
        margin-top: 6px;
      }
    }
  }
}
.notesSuppliersCreate_wrap {
  padding: 15px;
  .quill {
    background: #ffffff;
    position: relative;
    .my-editing-area {
      height: 300px;
    }
    .ql-toolbar.ql-snow {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .notesCreate_outer_content {
    background: #ffffff;
    border: 2px solid #285460;
    box-shadow: 0px 0px 5px 0px #285460;
    border-radius: 5px;

    // .notesCreate_inner_content {
    // }
    .notesCreateTop {
      .notesText {
        padding: 20px;
        border-bottom: 1px solid #bdbcbc;
        position: relative;
        font-size: 18px;
        .notesToText,
        .textLevel {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 130px;
          background: #ffffff;
          height: 66px;
          line-height: 66px;
          color: #a7a6a6;
          font-weight: 600;
          padding-left: 30px;
        }
        .inputBox {
          padding-left: 130px;
          width: -webkit-fill-available;
          width: -moz-available;
          border: none;
          font-weight: 400;
          padding-right: 150px;
          &:focus {
            border: none;
            box-shadow: none;
            outline: none;
          }
          &::placeholder {
            color: #bdbcbc;
          }
        }
      }
    }
    .message_button {
      padding: 20px;
      .btnMessage {
        text-align: center;
        padding: 0 20px;
        font-size: 16px;
        margin-right: 15px;
        border-radius: 5px;
        height: 45px;
        line-height: 45px;
        font-weight: 600;

        .svg-inline--fa {
          float: left;
          margin-right: 15px;
          line-height: 45px;
          margin-top: 15px;
        }
      }
      .btn_send {
        background: #54a958;
        color: #ffffff;
      }
      .btn_discard {
        background: #ffffff;
        border: 1px solid #a7a6a6;
        color: #a7a6a6;
      }
    }
  }

  .tagOrder {
    display: inline-block;
    position: absolute;
    top: 16px;
    right: 10px;
    .dropdown {
      float: left;
      button {
        box-shadow: none !important;
        font-weight: 600;
        color: #5160ec;
      }
    }
  }
}
