//Sidebar Order List
.sidebarOrderList {
  .actionAndTitle {
    color: $theme-green;
    .title {
      i {
        font-size: 20px;
      }
      h4 {
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
  .filterButton {
    button {
      padding: 4px 8px;
    }
  }
  .orderList {
    height: calc(100vh - 305px);
    overflow: auto;
    padding-top: 8px;
  }
}
.menuButtonAll {
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 6px;
  padding: 2px 5px;
}
.menuButtonAllDue {
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1px solid blue;
  border-radius: 6px;
  padding: 2px 5px;
}
.menuButtonAllCompleted {
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1px solid green;
  border-radius: 6px;
  padding: 2px 5px;
}
.menuButtonAllMissed {
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1px solid red;
  border-radius: 6px;
  padding: 2px 5px;
}
