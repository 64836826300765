@import '../../../../assets/scss/style.scss';

.mainLayout {
  background-color: $mainLayout;
  height: 100vh;
  .pageBodyAndHeader {
    .header {
      position: sticky;
      top: 0;
      z-index: 40;
    }
  }
  .pageBodyAndDrawer {
    .contentWrapper {
      transition: $sidebar-transition;
      // padding-right: 0.25rem;
      padding-left: 2rem;
      background: #f2f5f9;
    }

    &.drawerOpen {
      .contentWrapper {
        padding-left: calc(40px + 2rem);
      }
      @media only screen and (max-width: 991px) {
        .contentWrapper {
          padding-left: 2rem;
        }
      }
    }
  }
  .footer {
    box-shadow: 0px 0px 15px rgba(25, 72, 2, 0.89);
    z-index: 10;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #fff;
  }
}
